import { Box, Typography, makeStyles } from "@material-ui/core";
import dayjs from "dayjs";
import React, { useState, useEffect, useCallback } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useRecoilState } from "recoil";

import { LeaseContractTypeOfTheIncomeOfTheBeginningOfContractEnum } from "../../../../../api/generated";
import { CustomButton } from "../../../../../scripts/components/renewal_v1/button";
import { CustomForm } from "../../../../../scripts/components/renewal_v1/form";
import CustomFormLabel from "../../../../../scripts/components/renewal_v1/form_label";
import { CustomSelector } from "../../../../../scripts/components/renewal_v1/selecter";
import { Toast } from "../../../../../scripts/components/renewal_v1/toast";
import { DatePickerComponent } from "../../../../../scripts/components/renewal_v1/year_month_picker";
import { ConsiderationPropertyRegistrationState } from "../../../../../view_models/atoms";

const useStyles = makeStyles((theme) => ({
  content: {
    width: 640,
    [theme.breakpoints.down("sm")]: {
      width: 342,
    },
    margin: "8px  auto 0px auto",
  },
  block: {
    marginBottom: 24,
  },
  blockTitle: {
    color: theme.palette.text.primary,
    marginBottom: 8,
  },
  formBlock: {
    backgroundColor: theme.palette.background.default,
    padding: "8px 16px",
    borderRadius: 8,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      borderBottom: "none",
    },
  },
}));

interface AddNewConsiderationPropertyCashFlowPageProps {
  onBack: () => void;
  validate: () => Promise<boolean>;
}

export const prefecture_unselected = "";
const LeaseContractTypeOfTheIncomeOfTheBeginningOfContractOptions = [
  { value: "", label: "未選択" },
  { value: LeaseContractTypeOfTheIncomeOfTheBeginningOfContractEnum.KeyMoney, label: "礼金" },
  { value: LeaseContractTypeOfTheIncomeOfTheBeginningOfContractEnum.SecurityDeposit, label: "更新料" },
];
const LeaseContractTypeOfTheCostOfTheBeginningOfContractOptions = [
  { value: "", label: "未選択" },
  { value: "2", label: "新規賃貸契約手数料" },
  { value: "3", label: "賃貸契約更新時手数料" },
];

const AddNewConsiderationPropertyCashFlowPage: React.FC<AddNewConsiderationPropertyCashFlowPageProps> = ({
  onBack,
  validate,
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();
  const [formData] = useRecoilState(ConsiderationPropertyRegistrationState);
  const watchValues = useWatch({
    name: ["type_of_the_income_of_the_beginning_of_contract", "starts_at", "starts_at_day", "ends_at"],
    control: control,
  });

  // サーバーエラー
  const [serverError, setSeverError] = useState<string>("");
  const [dateError, setDateError] = useState<string>("");

  const handleNext = async () => {
    const isValid = await validate();
    const data = getValues();
    console.log(data);
    if (isValid) {
      console.log("submit");
      console.log(data);
    }
  };

  const handleClose = useCallback(() => {
    setSeverError("");
  }, []);

  useEffect(() => {
    if (dayjs(watchValues.ends_at).isBefore(dayjs(watchValues.starts_at))) {
      setDateError("終了日は開始日より後の日付を選択してください。");
    } else {
      setDateError("");
    }
  }, [watchValues.starts_at, watchValues.ends_at]);

  // 開始日が変更されたら終了日を更新
  useEffect(() => {
    if (watchValues.starts_at && watchValues.starts_at_day) {
      const startsAtDate = dayjs(watchValues.starts_at);
      const startDate = startsAtDate.date(parseInt(watchValues.starts_at_day));
      const endDate = startDate.add(2, "year").subtract(1, "day");

      setValue("ends_at", endDate.toDate());
      setValue("ends_at_day", endDate.format("D"));
    }
  }, [watchValues.starts_at, watchValues.starts_at_day, setValue]);

  // 選択肢
  return (
    <>
      <Box>
        <Box className={classes.content}>
          <Box className={classes.block}>
            <Typography variant="h4" className={classes.blockTitle}>
              収入予定
            </Typography>
            <Box className={classes.formBlock}>
              <Controller
                name="monthly_fee"
                control={control}
                rules={{
                  required: "入力してください。",
                }}
                defaultValue={formData.monthly_fee || ""}
                render={({ ...field }) => (
                  <CustomForm
                    {...field}
                    label="家賃収入"
                    required
                    error={!!errors.monthly_fee}
                    errorText={errors.monthly_fee?.message}
                    onChange={field.onChange}
                    placeHolder="100,000,000"
                    unitSuffix="円"
                    formatNumeric
                  />
                )}
              />
              <Controller
                name="monthly_management_fee"
                control={control}
                rules={{
                  required: "入力してください。",
                }}
                defaultValue={formData.monthly_management_fee || ""}
                render={({ ...field }) => (
                  <CustomForm
                    {...field}
                    label="管理費収入"
                    required
                    error={!!errors.monthly_management_fee}
                    errorText={errors.monthly_management_fee?.message}
                    onChange={field.onChange}
                    placeHolder="100,000,000"
                    unitSuffix="円"
                    formatNumeric
                  />
                )}
              />
              <Controller
                name="type_of_the_income_of_the_beginning_of_contract"
                control={control}
                defaultValue={formData.type_of_the_income_of_the_beginning_of_contract || ""}
                render={({ ...field }) => (
                  <CustomSelector
                    {...field}
                    label="賃貸借契約開始時における収入"
                    onChange={field.onChange}
                    error={!!errors.type_of_the_income_of_the_beginning_of_contract}
                    errorText={errors.type_of_the_income_of_the_beginning_of_contract?.message}
                    options={LeaseContractTypeOfTheIncomeOfTheBeginningOfContractOptions}
                  />
                )}
              />
              {/* security_deposit */}
              {watchValues.type_of_the_income_of_the_beginning_of_contract ===
                LeaseContractTypeOfTheIncomeOfTheBeginningOfContractEnum.SecurityDeposit && (
                <Controller
                  name="security_deposit"
                  control={control}
                  rules={{
                    required: "入力してください。",
                    pattern: {
                      value: /^[0-9]+(\.[0-9]+)?$/,
                      message: "半角数字で入力してください。",
                    },
                  }}
                  defaultValue={formData.security_deposit || ""}
                  render={({ ...field }) => (
                    <CustomForm
                      {...field}
                      label="更新料"
                      required
                      error={!!errors.security_deposit}
                      errorText={errors.security_deposit?.message}
                      onChange={field.onChange}
                      placeHolder="1"
                      unitSuffix="ヶ月分"
                    />
                  )}
                />
              )}
              {/* 礼金 */}
              {watchValues.type_of_the_income_of_the_beginning_of_contract ===
                LeaseContractTypeOfTheIncomeOfTheBeginningOfContractEnum.KeyMoney && (
                <Controller
                  name="key_money"
                  control={control}
                  rules={{
                    required: "入力してください。",
                    pattern: {
                      value: /^[0-9]+(\.[0-9]+)?$/,
                      message: "半角数字で入力してください。",
                    },
                  }}
                  defaultValue={formData.key_money || ""}
                  render={({ ...field }) => (
                    <CustomForm
                      {...field}
                      label="礼金"
                      required
                      error={!!errors.key_money}
                      errorText={errors.key_money?.message}
                      onChange={field.onChange}
                      placeHolder="1"
                      unitSuffix="ヶ月分"
                    />
                  )}
                />
              )}
            </Box>
          </Box>

          <Box className={classes.block}>
            <Typography variant="h4" className={classes.blockTitle}>
              支出予定
            </Typography>
            <Box className={classes.formBlock}>
              <Controller
                name="building_management_cost"
                control={control}
                rules={{
                  required: "入力してください。",
                }}
                defaultValue={formData.building_management_cost || ""}
                render={({ ...field }) => (
                  <CustomForm
                    {...field}
                    label="建物管理費"
                    required
                    error={!!errors.building_management_cost}
                    errorText={errors.building_management_cost?.message}
                    onChange={field.onChange}
                    placeHolder="100,000,000"
                    unitSuffix="円"
                    formatNumeric
                  />
                )}
              />
              <Controller
                name="repair_fund_cost"
                control={control}
                rules={{
                  required: "入力してください。",
                }}
                defaultValue={formData.repair_fund_cost || ""}
                render={({ ...field }) => (
                  <CustomForm
                    {...field}
                    label="修繕積立金"
                    required
                    error={!!errors.repair_fund_cost}
                    errorText={errors.repair_fund_cost?.message}
                    onChange={field.onChange}
                    placeHolder="100,000,000"
                    unitSuffix="円"
                    formatNumeric
                  />
                )}
              />
              <Controller
                name="management_cost"
                control={control}
                rules={{
                  required: "入力してください。",
                }}
                defaultValue={formData.management_cost || ""}
                render={({ ...field }) => (
                  <CustomForm
                    {...field}
                    label="賃貸管理費"
                    required
                    error={!!errors.management_cost}
                    errorText={errors.management_cost?.message}
                    onChange={field.onChange}
                    placeHolder="100,000,000"
                    unitSuffix="円"
                    formatNumeric
                  />
                )}
              />
              <Controller
                name="misc_cost"
                control={control}
                defaultValue={formData.misc_cost || ""}
                render={({ ...field }) => (
                  <CustomForm
                    {...field}
                    label="その他経費"
                    error={!!errors.misc_cost}
                    errorText={errors.misc_cost?.message}
                    onChange={field.onChange}
                    placeHolder="100,000,000"
                    unitSuffix="円"
                    formatNumeric
                  />
                )}
              />
              {/* 新規賃貸契約手数料 */}
              {watchValues.type_of_the_income_of_the_beginning_of_contract ===
                LeaseContractTypeOfTheIncomeOfTheBeginningOfContractEnum.KeyMoney && (
                <Controller
                  name="outsourcing_fee_rate_of_new_lease_contract"
                  control={control}
                  rules={{
                    required: "入力してください。",
                    pattern: {
                      value: /^[0-9]+(\.[0-9]+)?$/,
                      message: "半角数字で入力してください。",
                    },
                  }}
                  defaultValue={formData.outsourcing_fee_rate_of_new_lease_contract || ""}
                  render={({ ...field }) => (
                    <CustomForm
                      {...field}
                      label="新規賃貸契約手数料"
                      required
                      error={!!errors.outsourcing_fee_rate_of_new_lease_contract}
                      errorText={errors.outsourcing_fee_rate_of_new_lease_contract?.message}
                      onChange={field.onChange}
                      placeHolder="0.5"
                      unitSuffix="ヶ月分"
                    />
                  )}
                />
              )}
              {/* 賃貸契約更新時手数料 */}
              {watchValues.type_of_the_income_of_the_beginning_of_contract ===
                LeaseContractTypeOfTheIncomeOfTheBeginningOfContractEnum.SecurityDeposit && (
                <Controller
                  name="outsourcing_fee_rate_of_updating_lease_contract"
                  control={control}
                  rules={{
                    required: "入力してください。",
                    pattern: {
                      value: /^[0-9]+(\.[0-9]+)?$/,
                      message: "半角数字で入力してください。",
                    },
                  }}
                  defaultValue={formData.outsourcing_fee_rate_of_updating_lease_contract || ""}
                  render={({ ...field }) => (
                    <CustomForm
                      {...field}
                      label="賃貸契約更新時手数料"
                      required
                      error={!!errors.outsourcing_fee_rate_of_updating_lease_contract}
                      errorText={errors.outsourcing_fee_rate_of_updating_lease_contract?.message}
                      onChange={field.onChange}
                      placeHolder="0.5"
                      unitSuffix="ヶ月分"
                    />
                  )}
                />
              )}
            </Box>
          </Box>

          <Box className={classes.block}>
            <Typography variant="h4" className={classes.blockTitle}>
              賃貸借契約期間の予定
            </Typography>
            <Box className={classes.formBlock}>
              <Box mb={1}>
                <CustomFormLabel
                  label="賃貸借契約の開始月"
                  required
                  info="購入した年月日を選択してください。"
                  helperText="※一棟の場合は直近で契約更新した部屋の期間を入力"
                />
              </Box>

              <Controller
                name="starts_at"
                control={control}
                rules={{ required: true }}
                defaultValue={formData.starts_at || dayjs().format("YYYY-MM")}
                render={({ ...field }) => (
                  <DatePickerComponent {...field} labelLight="年月" onChange={field.onChange} />
                )}
              />

              <Controller
                name="starts_at_day"
                control={control}
                rules={{
                  required: "入力してください。",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "半角数字で入力してください。",
                  },
                }}
                defaultValue={formData.starts_at_day || "1"}
                render={({ ...field }) => (
                  <CustomForm
                    {...field}
                    labelLight="日にち"
                    helperText="不明の場合は値を「1日」のままお進みください。"
                    error={!!errors.starts_at_day}
                    errorText={errors.starts_at_day?.message}
                    onChange={field.onChange}
                    placeHolder="1"
                    unitSuffix="日"
                    isShort
                  />
                )}
              />

              <Box mb={1}>
                <CustomFormLabel label="賃貸借契約の終了月" required info="購入した年月日を選択してください。" />
              </Box>

              <Controller
                name="ends_at"
                control={control}
                rules={{ required: true }}
                defaultValue={formData.ends_at || dayjs().add(2, "year").add(-1, "day").format("YYYY-MM")}
                render={({ ...field }) => <DatePickerComponent labelLight="年月" {...field} />}
              />
              <Controller
                name="ends_at_day"
                control={control}
                rules={{
                  required: "入力してください。",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "半角数字で入力してください。",
                  },
                }}
                defaultValue={formData.ends_at_day || "1"}
                render={({ ...field }) => (
                  <CustomForm
                    {...field}
                    labelLight="日にち"
                    helperText="不明の場合は値を「1日」のままお進みください。"
                    error={!!errors.ends_at_day}
                    errorText={errors.ends_at_day?.message}
                    onChange={field.onChange}
                    placeHolder="1"
                    unitSuffix="日"
                    isShort
                  />
                )}
              />
              {dateError && (
                <Typography color="error" variant="body2">
                  {dateError}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box mt={5}>
          <CustomButton type="submit" customVariant="filled" onClick={handleNext}>
            登録する
          </CustomButton>
          <Box display="flex" margin="32px auto" justifyContent="center">
            <Typography variant="button" className={classes.link} onClick={onBack}>
              前に戻る
            </Typography>
          </Box>
        </Box>
      </Box>
      <Toast open={!!serverError} variant="error" message={serverError} onClose={handleClose} />
    </>
  );
};

export default AddNewConsiderationPropertyCashFlowPage;
