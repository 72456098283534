import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";

import { CustomButton } from "../../../scripts/components/renewal_v1/button";

export const TaxReturnSection = () => {
  const history = useHistory();

  return (
    <Paper>
      <Box p={2}>
        <Box mb={2}>
          <Typography variant="h3">確定申告サポート機能</Typography>
        </Box>
        <Typography>
          損益計算書と収支表を簡単作成！
          <br />
          必要情報を入力するだけ。
        </Typography>
        <Box mt={1}>
          <Typography variant="body2" color="textSecondary">
            ※初回はサンプル物件の情報が表示されています
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" mt={2}>
          <CustomButton customVariant="filled" onClick={() => history.push("/balance")}>
            収支表・損益計算書を確認する
          </CustomButton>
        </Box>
      </Box>
    </Paper>
  );
};
