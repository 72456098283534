import { Box, Divider, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Dayjs } from "dayjs";
import React from "react";

import CustomFormLabel from "./form_label";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  selector: {
    display: "flex",
    alignItems: "center",
    minWidth: 161,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 4,
    height: "56px",
  },
  button: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  dateText: {
    flex: 1,
    textAlign: "center",
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
  divider: {
    height: "56px",
    backgroundColor: theme.palette.grey[400],
  },
}));

type MonthRangeSelectorProps = {
  label?: string;
  startMonth: Dayjs;
  endMonth: Dayjs;
  onChange: (startMonth: Dayjs, endMonth: Dayjs) => void;
};

export const MonthRangeSelector: React.FC<MonthRangeSelectorProps> = ({ label, startMonth, endMonth, onChange }) => {
  const classes = useStyles();

  const handlePreviousYear = () => {
    onChange(startMonth.subtract(12, "month"), endMonth.subtract(12, "month"));
  };

  const handleNextYear = () => {
    onChange(startMonth.add(12, "month"), endMonth.add(12, "month"));
  };

  return (
    <Box className={classes.root}>
      {label && (
        <Box mb={1}>
          <CustomFormLabel label={label} />
        </Box>
      )}
      <Box className={classes.selector}>
        <IconButton className={classes.button} onClick={handlePreviousYear} size="small">
          <ChevronLeftIcon />
        </IconButton>
        <Divider orientation="vertical" className={classes.divider} />
        <Typography variant="body1" className={classes.dateText}>
          {startMonth.format("YYYY/MM")}
          　〜
          {endMonth.format("YYYY/MM")}
        </Typography>
        <Divider orientation="vertical" className={classes.divider} />
        <IconButton className={classes.button} onClick={handleNextYear} size="small">
          <ChevronRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default MonthRangeSelector;
