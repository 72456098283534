import { debounce } from "@material-ui/core";
import dayjs from "dayjs";

import {
  Base64EncodedImage,
  LeaseContract,
  LeaseContractTypeOfTheIncomeOfTheBeginningOfContractEnum,
  MonthlyCashflowResult,
  Property,
  PropertyBuilder,
  PropertyBuilderLayoutEnum,
  PropertyBuilderManagementTypeEnum,
  PropertyBuilderSalesStatusEnum,
  PropertyBuilderStructureEnum,
  PropertyLayoutEnum,
  PropertyManagementTypeEnum,
  PropertySalesStatusEnum,
  PropertyStructureEnum,
  PropertyType,
  RegisteredStatusEnum,
} from "../../api/generated";
import { NEW_MANAGEMENT_TYPE_KEY_VALUES } from "../../api/mappings/property";
import { PropallyApiFactory } from "../../module/custom_api_factory";
import { sampleResponse } from "../../pages/renewal_v1/management/add_new_property/const";

import { parseFormattedNumber } from "./format";

const fp = PropallyApiFactory();
/**
 * データのマッピング処理
 */
export const formDataMappingToProperty = (data: any, property_type: PropertyType): Property => {
  return {
    ...data,
    // 必須項目
    name: data.name,
    property_type: property_type,
    payment_period: Number(data.payment_period),
    structure: PropertyStructureEnum.Rc造鉄筋コンクリート造,
    bought_at: data.bought_at,
    // bought_at: "2024-05-12",
    // payment_starts_at: "2024-05-14",
    payment_starts_at: data.payment_starts_at,
    // 任意項目
    room_number: data.room_number,
    layout: data.layout ? (data.layout as PropertyLayoutEnum) : PropertyLayoutEnum.未選択,
    total_price_of_property: parseFormattedNumber(data.total_price_of_property),
    loan_amount: parseFormattedNumber(data.loan_amount),
    management_type:
      (NEW_MANAGEMENT_TYPE_KEY_VALUES.find((item) => item.value === data.management_type)
        ?.key as PropertyManagementTypeEnum) || PropertyManagementTypeEnum.MoneyCollectionAgency,
    floor: Number(data.floor),
    walk: Number(data.walk),
    occupied_area_m2: Number(data.occupied_area_m2),
    priceof_building: parseFormattedNumber(data.priceof_building),
    priceof_tax: parseFormattedNumber(data.priceof_tax),
    priceof_facilities: parseFormattedNumber(data.priceof_facilities),
    priceof_land: parseFormattedNumber(data.priceof_land),
    sales_status: data.sales_status as PropertySalesStatusEnum,
    property_acquisition_tax: parseFormattedNumber(data.property_acquisition_tax),
    misc_expences_at_bought: parseFormattedNumber(data.misc_expences_at_bought),
  };
};

export const formDataMappingToLeaseContract = (data: any): LeaseContract => {
  const starts_at = dayjs(data.starts_at).format("YYYY-MM") + "-" + data.starts_at_day;
  const ends_at = dayjs(data.ends_at).format("YYYY-MM") + "-" + data.ends_at_day;
  return {
    monthly_fee: parseFormattedNumber(data.monthly_fee),
    monthly_management_fee: parseFormattedNumber(data.monthly_management_fee),
    starts_at: starts_at,
    ends_at: ends_at,
    key_money: parseFormattedNumber(data.key_money),
    security_deposit: parseFormattedNumber(data.security_deposit),
    type_of_the_income_of_the_beginning_of_contract:
      data.type_of_the_income_of_the_beginning_of_contract as LeaseContractTypeOfTheIncomeOfTheBeginningOfContractEnum,
    outsourcing_fee_rate_of_new_lease_contract: parseFormattedNumber(data.outsourcing_fee_rate_of_new_lease_contract),
    outsourcing_fee_rate_of_updating_lease_contract: parseFormattedNumber(
      data.outsourcing_fee_rate_of_updating_lease_contract
    ),
  };
};

export const formDataMappingToLeaseContractForUpdate = (
  data: any,
  property: Property,
  lease_contract: LeaseContract
): PropertyBuilder => {
  const monthly_cashflow_result: MonthlyCashflowResult = {
    loan_interest_rate_percentage: parseFormattedNumber(data.loan_interest_rate_percentage),
    management_cost: parseFormattedNumber(data.management_cost),
    building_management_cost: parseFormattedNumber(data.building_management_cost),
    repair_fund_cost: parseFormattedNumber(data.repair_fund_cost),
    misc_cost: parseFormattedNumber(data.misc_cost),
  };
  return {
    ...property,
    structure: property.structure as unknown as PropertyBuilderStructureEnum,
    layout: property.layout as unknown as PropertyBuilderLayoutEnum,
    management_type: property.management_type as unknown as PropertyBuilderManagementTypeEnum,
    sales_status: property.sales_status as unknown as PropertyBuilderSalesStatusEnum,
    lease_contract: lease_contract,
    cashflow_result_of_the_first_month: monthly_cashflow_result,
  };
};

/**
 * 物件の新規登録処理
 */

export const postProperty = async (property_builder: PropertyBuilder, base64_encoded_image?: Base64EncodedImage) => {
  const postResponse = await fp.v1PropertiesPost(property_builder, { withCredentials: true });
  if (base64_encoded_image) {
    await fp.v1PropertiesPropertyIdImagesPost(postResponse.data.id, base64_encoded_image, {
      withCredentials: true,
    });
  }
  return postResponse;
};

export const handleFirstRegistration = async (setUserDataFirstRegisteredStatus: any): Promise<void> => {
  // 現在のステータスを取得
  const registerStatusResponse = await fp.v1UserDataFirstRegisteredStatusGet({
    withCredentials: true,
  });
  if (registerStatusResponse.data.status !== RegisteredStatusEnum.Property) {
    // 初回登録完了時のみ実行する処理

    // 初回登録済みステータスを更新
    await fp
      .v1UserDataFirstRegisteredStatusPatch(
        {
          status: RegisteredStatusEnum.Property,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setUserDataFirstRegisteredStatus(res.data.status);
      });
  }
};

export const handleSubmissionError = (setManagementListSnackBarState: any, history: any): void => {
  // 物件一覧ページでのスナックバー表示用
  setManagementListSnackBarState({ isOpen: true, message: "物件を追加できませんでした", status: "error" });
  history.push("/management");
};

export const cleanupAfterSubmission = (
  removeStoragePropertyForm,
  removeStorageSelectedPropertyId,
  removeStorageSelectedProperty,
  setManagementListSnackBarState,
  history,
  requestInitialLoading
) => {
  removeStoragePropertyForm();
  removeStorageSelectedPropertyId();
  removeStorageSelectedProperty();
  // 物件一覧ページでのスナックバー表示用
  setManagementListSnackBarState({ isOpen: true, message: "物件を追加しました", status: "success" });
  history.push("/management");
  requestInitialLoading();
};

/**
 * オートコンプリートの処理
 */

export const getDebounceBuildingsSearch = (
  setValue: Function,
  setBuildingOptions: Function,
  setRoomOptions: Function,
  fetchBuildingOptions: Function
) => {
  return debounce((searchBuildingName: string) => {
    setValue("name", searchBuildingName);
    setBuildingOptions([]);
    setRoomOptions([]);

    if (searchBuildingName.length < 2) return;
    fetchBuildingOptions(searchBuildingName);
  }, 100);
};

export const fetchBuildingOptions = (setBuildingOptions: Function) => {
  return (searchBuildingName: string) => {
    setBuildingOptions(sampleResponse.buildings);
    fp.v1HowmaBuildingsPost({ text: searchBuildingName }, { withCredentials: true })
      .then((res) => {
        setBuildingOptions(res.data.buildings);
      })
      .catch(() => {
        console.log("物件検索に失敗しました。");
      });
  };
};

export const getFetchRoomOptions = (setRoomOptions: Function) => {
  return (buildingId: number) => {
    fp.v1HowmaBuildingsBuildingIdGet(buildingId, { withCredentials: true })
      .then((res) => {
        setRoomOptions(res.data.rooms);
      })
      .catch(() => {
        console.log("部屋情報取得に失敗しました。");
      });
  };
};

/**
 * 物件購入日から対象物件適用消費税率を取得
 */
export const getTaxRateAtBought = (bought_at: string): number => {
  const tax_base_date = dayjs(bought_at);
  // 消費税率の変遷について see https://www.zeiken.co.jp/zeikenpress/column/03/ 記事最下部
  let tax_rate_at_bought: number;
  if (tax_base_date.isBefore(dayjs("1989-04-01"))) {
    tax_rate_at_bought = 0;
  } else if (tax_base_date.isBefore(dayjs("1997-04-01"))) {
    tax_rate_at_bought = 0.03;
  } else if (tax_base_date.isBefore(dayjs("2014-04-01"))) {
    tax_rate_at_bought = 0.05;
  } else if (tax_base_date.isBefore(dayjs("2019-10-01"))) {
    tax_rate_at_bought = 0.08;
  } else {
    tax_rate_at_bought = 0.1;
  }
  return tax_rate_at_bought;
};
