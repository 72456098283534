import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import * as React from "react";

interface Row {
  title: string;
  values: string[];
  type: "category" | "item" | "sum";
}

interface Props {
  data: Row[];
  monthlyResults: any[];
  selectedStartingMonth: dayjs.Dayjs;
  isMobile: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableHead: {
      backgroundColor: theme.palette.common.white,
      borderBottom: `solid 1px ${theme.palette.primary.main}`,
      borderRight: `solid 1px ${theme.palette.grey[300]}`,
    },
    tableCell: {
      fontSize: "0.75rem",
      minWidth: 120,
      borderRight: `solid 1px ${theme.palette.grey[300]}`,
      "&.category": {
        fontSize: ({ isMobile }: { isMobile: boolean }) => (isMobile ? "0.9em" : "1.17em"),
        fontWeight: "bold",
        borderBottom: "none",
        backgroundColor: theme.palette.common.white,
        paddingLeft: 0,
        paddingBottom: theme.spacing(1.25),
      },
      "&.item": {
        backgroundColor: theme.palette.grey[200],
        borderBottom: "none",
      },
      "&.sum": {
        backgroundColor: theme.palette.grey[200],
        fontWeight: "bold",
        borderTop: `solid 1px ${theme.palette.grey[300]}`,
        borderBottom: "none",
      },
    },
    stickyCell: {
      whiteSpace: "nowrap",
      left: 0,
      position: "sticky",
      zIndex: theme.zIndex.appBar + 2,
      borderRight: "none",
    },
  })
);

export const SheetTable: React.FC<Props> = ({ data, monthlyResults, selectedStartingMonth, isMobile }) => {
  const classes = useStyles({ isMobile });
  const isActualResult = (d: dayjs.Dayjs) => d.isBefore(dayjs().startOf("month"));

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={`${classes.stickyCell} ${classes.tableHead}`} />
            {monthlyResults.map((_, i) => (
              <TableCell key={i} align="right" className={classes.tableHead}>
                <Box fontWeight="bold">{isActualResult(selectedStartingMonth.add(i, "month")) ? "実績" : "予測"}</Box>
                <Box fontWeight="bold">
                  {i === monthlyResults.length - 1 ? "年合計" : selectedStartingMonth.add(i, "month").format("YYYY/MM")}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => (
            <TableRow key={i}>
              <TableCell className={`${classes.stickyCell} ${classes.tableCell} ${row.type}`}>
                <Box>{row.title}</Box>
              </TableCell>
              {row.values.map((value, j) => (
                <TableCell key={j} align="right" className={`${classes.tableCell} ${row.type}`}>
                  {value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
