import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import dayjs from "dayjs";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import { useRecoilValue } from "recoil";

import { Document } from "../../../../api/generated";
import { PropallyApiFactory } from "../../../../module/custom_api_factory";
import { DropDownMenuItem } from "../../../../scripts/components/renewal_v1/dropdown_menu";
import Header from "../../../../scripts/components/renewal_v1/header";
import { DeleteImageModal } from "../../../../scripts/components/renewal_v1/property/delete_image_modal";
import { DeletePropertyModal } from "../../../../scripts/components/renewal_v1/property/delete_property_modal";
import { NoDocumentComponent } from "../../../../scripts/components/renewal_v1/property/no_document";
import { PdfViewerComponent } from "../../../../scripts/components/renewal_v1/property/pdf_viewer";
import { PropertyOperationTemplate } from "../../../../scripts/components/renewal_v1/template/property_operation_template";
import theme from "../../../../scripts/components/renewal_v1/theme";
import { fileToBase64EncodedImage } from "../../../../utilities/file_to_base64_encoded_image";
import { useDevice } from "../../../../utilities/sp/use_device";
import { SelectedProperty } from "../../../../view_models/property_selectors";
import { DocumentTypeOptions } from "../const";

const useStyles = makeStyles((theme) => ({
  background: {
    textAlign: "center",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(7),
    },
  },
  fileList: {
    height: "70vh",
    padding: 16,
  },
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  file: {
    opacity: "0",
    appearance: "none",
    position: "absolute",
  },
  icon: {
    padding: 0,
  },
  image: {
    width: "100%",
  },
}));

type Column = {
  id: string;
  label: string;
  minWidth?: number;
};

const columns: Column[] = [
  {
    id: "name",
    label: "ファイル名",
    minWidth: 170,
  },
  {
    id: "created_at",
    label: "作成日",
    minWidth: 110,
  },
  {
    id: "download",
    label: "",
    minWidth: 5,
  },
  {
    id: "operation",
    label: "",
    minWidth: 5,
  },
];

export const PropertyDocumentListPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { isMobile } = useDevice();
  const { id } = useParams<{ id?: string }>();
  const selected_property = useRecoilValue(SelectedProperty);
  const [documents, setDocuments] = React.useState<Document[]>([]);
  const [selectedDocument, setSelectedDocument] = React.useState<Document>(null);
  const [selectedPdfBlob, setSelectedPdfBlob] = React.useState<any>(null);
  // 削除モーダルの表示状態
  const [openDeletePropertyModal, setOpenDeletePropertyModal] = React.useState(false);
  // テーブル関連
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const fp = PropallyApiFactory();
  const documentId = React.useMemo(() => {
    if (isNaN(Number(id))) {
      history.goBack();
    } else {
      return Number(id);
    }
  }, [id]);

  const onClickPageBack = () => {
    history.push("/management/detail");
  };

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  // アイコンをクリックしてモーダルを開く
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  /**
   * ファイル一覧取得
   */
  const getDocuments = async () => {
    const fp = PropallyApiFactory();
    try {
      const { data } = await fp.v1PropertiesPropertyIdDocumentsDocumentTypeFilesGet(selected_property.id, documentId, {
        withCredentials: true,
      });
      setDocuments(data);
    } catch (e) {
      console.error(e);
      return;
    }
  };
  // ファイル一覧取得
  React.useEffect(() => {
    getDocuments();
  }, [documentId]);

  const documentName = DocumentTypeOptions.find((option) => option.value === documentId)?.label;

  // テーブル
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    if (file) {
      const base64_encoded_image = await fileToBase64EncodedImage(file);
      const fp = PropallyApiFactory();
      try {
        await fp.v1PropertiesPropertyIdDocumentsDocumentTypeFilesPost(
          selected_property.id,
          documentId,
          base64_encoded_image,
          {
            withCredentials: true,
          },
        );
        await getDocuments();
      } catch (e) {
        console.error(e);
        return;
      } finally {
        // この処理を行わないとリロードしないと再アップロードできなくなる
        e.target.value = "";
      }
    }
  };

  // ドキュメント詳細を選択
  const handleDocumentSelect = async (document: Document) => {
    if (document.name.endsWith(".pdf")) {
      try {
        const { data } = await fp.v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdGet(
          selected_property.id,
          documentId,
          document.id,
          {
            responseType: "blob",
            withCredentials: true,
          },
        );
        setSelectedPdfBlob(data);
      } catch (e) {
        console.error(e);
      }
    }
    setSelectedDocument(document);
  };

  /**
   * ファイル選択後の処理
   */

  // ドキュメントのファイル形式判定
  const isPdf = React.useMemo(() => {
    if (selectedDocument) {
      return selectedDocument.name.endsWith(".pdf");
    }
    return false;
  }, [selectedDocument]);

  // ダウンロード処理
  const downloadFile = async (fileBlob: any, fileName: string) => {
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      //  IE向け
      (window.navigator as any).msSaveOrOpenBlob(fileBlob, fileName);
    } else if (Capacitor.isNativePlatform()) {
      //  iOS/Androidアプリ向け
      const url = URL.createObjectURL(fileBlob);

      await Browser.open({ url });

      // URLの解放
      URL.revokeObjectURL(url);
    } else {
      const url = URL.createObjectURL(fileBlob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };

  // スマートフォン向けのダウンロード処理
  const downloadFileForSP = async (response: any, fileName: string) => {
    try {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = response.signed_url;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(response.signed_url);
      document.body.removeChild(a);
    } catch (e) {
      console.error(e);
    }
  };

  // ダウンロードボタンクリック時の処理　※詳細画面
  const handleDownload = async () => {
    try {
      const { data } = await fp.v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdGet(
        selected_property.id,
        documentId,
        selectedDocument?.id,
        {
          responseType: "blob",
          withCredentials: true,
        }
      );
      downloadFile(data, selectedDocument.name);
    } catch (e) {
      console.error(e);
    }
  };

  // ダウンロードボタンを押したときの処理
  const onClickDownload = async (document: Document) => {
    try {
      console.log("BBBB ", selected_property.id);
      console.log("BBBB ", documentId);
      console.log("BBBB ", selectedDocument?.id);
      console.log("BBBB isNativePlatform", Capacitor.isNativePlatform());
      console.log("BBBB ", document.file_url);
      // if (Capacitor.isNativePlatform()) {
      if (true) {
        const { data } = await fp.v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdUrlGet(
          selected_property.id,
          documentId,
          document?.id,
          document?.name,
          {
            withCredentials: true,
          },
        );
        console.log("BBBB ", data);
        console.log("BBBB ", document.name);
        downloadFileForSP(data, document.name);
      } else {
        const { data } = await fp.v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdGet(
          selected_property.id,
          documentId,
          document?.id,
          {
            responseType: "blob",
            withCredentials: true,
          },
        );
        downloadFile(data, document.name);
        console.log("BBBB ", data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  // 削除ボタンクリック時の処理
  const onClickDelete = React.useCallback(
    async (document: Document) => {
      try {
        setSelectedDocument(document);
        await fp.v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdDelete(
          selected_property.id,
          documentId,
          selectedDocument?.id,
          {
            withCredentials: true,
          },
        );
        // 選択したファイルを削除したら、選択状態を解除する
        setSelectedDocument(null);
        getDocuments();
      } catch (e) {
        console.error(e);
      }
    },
    [selected_property.id, documentId, selectedDocument],
  );

  // ドキュメント詳細の初期化
  const resetSelectedDocument = () => {
    setSelectedDocument(null);
    setSelectedPdfBlob(null);
  };

  // メニュー
  const menuItems: DropDownMenuItem[] = React.useMemo(
    () => [
      {
        label: "ダウンロード",
        onClick: handleDownload,
        color: "textPrimary",
      },
      {
        label: "削除する",
        onClick: onClickDelete,
        color: "error",
      },
    ],
    [handleDownload, onClickDelete],
  );

  // ファイルを選択した時
  if (selectedDocument) {
    return (
      <PropertyOperationTemplate
        title={documentName}
        onBack={resetSelectedDocument}
        menuItems={menuItems}
        isDocumentView
      >
        <Box className={classes.background} style={{ backgroundColor: theme.palette.tertiary.main }}>
          {!isMobile && <Header title={documentName} onBack={resetSelectedDocument} menuItems={menuItems} />}
          {isPdf && selectedPdfBlob ? (
            <PdfViewerComponent fileBlob={selectedPdfBlob} />
          ) : (
            <Box
              width={isMobile ? "auto" : "400px"}
              margin="0 auto"
              p={3}
              textAlign="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <img src={selectedDocument.file_url} alt={selectedDocument.name} className={classes.image} />
            </Box>
          )}
        </Box>
      </PropertyOperationTemplate>
    );
  }

  return (
    <PropertyOperationTemplate title={documentName} onBack={onClickPageBack}>
      {/* ドキュメントがない時 */}
      {documents.length === 0 ? (
        <Box className={classes.background}>
          {!isMobile && <Header title={documentName} onBack={onClickPageBack} />}
          <Box
            width={isMobile ? "auto" : "400px"}
            margin="0 auto"
            px={3}
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <NoDocumentComponent onChangeFile={handleFileInput} />
          </Box>
        </Box>
      ) : (
        <Box className={classes.fileList}>
          {/** アップロードボタン */}
          <Box display="flex" justifyContent="center">
            <Button color="primary" variant="contained">
              書類をアップロードする
              <Input
                className={classes.file}
                type="file"
                name="image"
                inputProps={{ accept: "image/*,application/pdf" }}
                fullWidth
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleFileInput(e);
                }}
              />
            </Button>
          </Box>
          <Box height="15px" />
          <Typography variant="h3">アップロード済みのファイル</Typography>
          <Card>
            <Paper>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id} align="left" style={{ minWidth: column.minWidth }}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                          <TableCell key="name" align="left">
                            {row.name}
                          </TableCell>
                          <TableCell key="created_at" align="left">
                            {dayjs(row.created_at).format("YYYY/MM/DD")}
                          </TableCell>
                          <TableCell key="download" align="left">
                            <IconButton
                              aria-label="download"
                              className={classes.icon}
                              onClick={() => onClickDownload(row)}
                              // onClick={() => handleDocumentSelect(row)}
                            >
                              <label htmlFor="download-file">
                                <GetAppIcon
                                  style={{
                                    color: "#fff",
                                    background: "#009578",
                                    borderRadius: "50%",
                                    padding: "3px",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                />
                              </label>
                            </IconButton>
                          </TableCell>
                          <TableCell key="operation" align="left">
                            {/* <IconButton aria-label="delete" className={classes.icon} onClick={handleOpenModal}> */}
                            {/* <IconButton
                              aria-label="delete"
                              className={classes.icon}
                              onClick={() => handleDocumentSelect(row)}
                            > */}
                              <IconButton aria-label="delete" className={classes.icon} onClick={() => (window.location.href = "https://www.jstage.jst.go.jp/article/jae/41/1/41_1/_pdf/-char/en")}>
                              <label htmlFor="delete-file">
                                <DeleteForeverIcon
                                  style={{
                                    color: "#fff",
                                    background: "#DE351F",
                                    borderRadius: "50%",
                                    padding: "3px",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                />
                              </label>
                            </IconButton>
                            {openDeletePropertyModal && (
                            <DeleteImageModal
                              isOpen={isModalOpen}
                              onClose={() => setIsModalOpen(false)}
                              onClickCancel={() => setOpenDeletePropertyModal(false)}
                              // onClickCancel={() => console.log("aa")}
                              onClickDelete={() => onClickDelete(row)}
                            />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={documents.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}から${to} 件 / ${count}件中`;
                }}
                labelRowsPerPage={<>表示件数</>}
              />
            </Paper>
          </Card>
        </Box>
      )}
    </PropertyOperationTemplate>
  );
};
