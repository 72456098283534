// カンマを除く
export const removeComma = (value: string | number | undefined): string => {
  if (!value) return "";
  // 数値型や他の型の場合も文字列に変換してから処理
  return value.toString().replace(/,/g, "");
};

// 数値にパースする
export const parseFormattedNumber = (value: string | number | undefined): number => {
  if (!value) return 0;
  const strValue = removeComma(value);
  const parsed = parseFloat(strValue);
  return isNaN(parsed) ? 0 : parsed;
};
