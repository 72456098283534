import { Box, Typography, makeStyles } from "@material-ui/core";
import React, { FC, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useRecoilState } from "recoil";

import { CustomButton } from "../../../scripts/components/renewal_v1/button";
import { CustomForm } from "../../../scripts/components/renewal_v1/form";
import { CustomSelector } from "../../../scripts/components/renewal_v1/selecter";
import { RegistrationTemplate } from "../../../scripts/components/renewal_v1/template/registration_template";
import { theme } from "../../../theme";
import { UserRegistrationsAccoutFormState } from "../../../view_models/atoms";

const useStyles = makeStyles({
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      borderBottom: "none",
    },
  },
});

interface RegisterFinancialAssetsPageProps {
  totalSteps: number;
  currentStep: number;
  onNext: () => void;
  onBack: () => void;
  validate: () => Promise<boolean>;
}

const loan_ranges = [
  "なし",
  "〜500万円",
  "500〜1,000万円",
  "1,000〜2,000万円",
  "2,000〜3,000万円",
  "3,000〜5,000万円",
  "5,000万円〜1億円",
  "1〜2億円",
  "2〜3億円",
  "3〜5億円",
  "5〜10億円",
  "10億円〜",
];

const RegisterFinancialAssetsPage: FC<RegisterFinancialAssetsPageProps> = ({
  totalSteps,
  currentStep,
  onNext,
  onBack,
  validate,
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const [formData] = useRecoilState(UserRegistrationsAccoutFormState);
  const financial_asset = watch("financial_asset");

  const handleNext = async () => {
    const isValid = await validate();
    if (isValid) {
      onNext();
    }
  };

  // formDataが存在する場合に初期値を設定
  useEffect(() => {
    control.setValue("financial_asset", formData.financial_asset);
    control.setValue("holding_property_count", formData.holding_property_count);
    control.setValue("real_estate_loan_amount", formData.real_estate_loan_amount);
  }, []);

  const loanRangeOptions = loan_ranges.map((loanRange) => ({ value: loanRange, label: loanRange }));

  return (
    <RegistrationTemplate title="現在の資産について教えてください" totalSteps={totalSteps} currentStep={currentStep}>
      <Box mt={4}>
        <Controller
          name="financial_asset"
          control={control}
          rules={{ required: "金融資産（預金・株式等）を選択してください。" }}
          render={({ ...field }) => (
            <CustomSelector
              {...field}
              label="金融資産（預金・株式等）"
              error={!!errors.financial_asset}
              errorText={errors.financial_asset?.message}
              onChange={field.onChange}
              options={loanRangeOptions}
              placeHolder="選択してください"
            />
          )}
        />

        <Controller
          name="holding_property_count"
          control={control}
          rules={{
            required: "投資用不動産保有数を選択してください。",
            pattern: {
              value: /^[0-9]+$/,
              message: "半角数字で入力してください。",
            },
          }}
          defaultValue={""}
          render={({ ...field }) => (
            <CustomForm
              {...field}
              label="投資用不動産保有数"
              helperText="※住宅ローンで組んでいる物件は含みません"
              error={!!errors.holding_property_count}
              errorText={errors.holding_property_count?.message}
              onChange={field.onChange}
              placeHolder="入力してください"
              unitSuffix="件"
            />
          )}
        />

        <Controller
          name="real_estate_loan_amount"
          control={control}
          rules={{
            required: financial_asset !== "なし" ? "投資用不動産ローン合計を選択してください。" : false,
          }}
          defaultValue={""}
          render={({ ...field }) => (
            <CustomForm
              {...field}
              label="投資用不動産ローン合計"
              helperText="※住宅ローン残高は含みません。"
              error={!!errors.real_estate_loan_amount}
              errorText={errors.real_estate_loan_amount?.message}
              onChange={field.onChange}
              placeHolder="入力してください"
              unitSuffix="万円"
              formatNumeric
            />
          )}
        />
      </Box>
      <Box mt={3}>
        <CustomButton type="button" customVariant="filled" onClick={handleNext}>
          次へ
        </CustomButton>
        <Box display="flex" margin="32px auto" justifyContent="center">
          <Typography variant="button" className={classes.link} onClick={onBack}>
            戻る
          </Typography>
        </Box>
      </Box>
    </RegistrationTemplate>
  );
};

export default RegisterFinancialAssetsPage;
