import { Box } from "@material-ui/core";
import dayjs from "dayjs";
import React, { FC, useEffect } from "react";
import { useRecoilValueLoadable, useRecoilState } from "recoil";

import { ScreenNameEnum } from "../../../api/generated/api";
import { useGetProposalMatchings } from "../../../hooks/useGetProposalMatchings";
import { usePostUserAccessScreenLogs } from "../../../hooks/usePostUserAccessScreenLogs";
import { MypageTemplate } from "../../../scripts/components/renewal_v1/template/mypage_template";
import { SelectedStartingMonth, SelectedEndingMonth, useSelectOwnedProperties } from "../../../view_models/atoms";
import { LoanInformationOnToday, ReducedMonthlyBalanceSheets } from "../../../view_models/balancesheet_selectors";
import { OwnedProperties } from "../../../view_models/property_selectors";
import { Loading } from "../../loading";

import { CashFlowSection } from "./cash_flow_section";
import { MatchingAgentSection } from "./matching_agent_section";
import { TaxReturnSection } from "./tax_return_section";
import AppTour from "./tour";

export const MyPage: FC = () => {
  const loan_loadable = useRecoilValueLoadable(LoanInformationOnToday);
  const reduced_loadable = useRecoilValueLoadable(ReducedMonthlyBalanceSheets(null));

  const [selected_starting_month, setSelectedStartingYear] = useRecoilState(SelectedStartingMonth);
  const [selected_ending_month, setSelectedEndingYear] = useRecoilState(SelectedEndingMonth);
  const selectOwnedProperties = useSelectOwnedProperties();
  const properties_loadable = useRecoilValueLoadable(OwnedProperties);

  const { proposalMatchings } = useGetProposalMatchings();
  usePostUserAccessScreenLogs(ScreenNameEnum.Top);

  useEffect(() => {
    selectOwnedProperties();
    if (selected_ending_month.diff(selected_starting_month, "year") !== 45) {
      setSelectedStartingYear(dayjs().startOf("year"));
      setSelectedEndingYear(dayjs().add(45, "year").endOf("year"));
    }
  }, []);

  if (properties_loadable.state === "loading") {
    return <Loading />;
  }
  if (properties_loadable.state === "hasError") {
    throw properties_loadable.contents;
  }

  // // TODO WANTFIX: we can rewrite the following state checking as for-loop or iterator.
  // // I don't have any idea to pass type check of Typescript with the iterative approach.
  if (loan_loadable.state === "hasError") {
    throw loan_loadable.contents;
  }
  if (loan_loadable.state === "loading") {
    return <Loading />;
  }
  if (reduced_loadable.state === "hasError") {
    throw reduced_loadable.contents;
  } else if (reduced_loadable.state === "loading") {
    return <Loading />;
  }

  const this_month_sheet = reduced_loadable.contents.get(dayjs().format("YYYY/MM"));

  return (
    <MypageTemplate>
      <AppTour />
      <CashFlowSection
        thisMonthResult={this_month_sheet.result}
        properties={properties_loadable.contents}
        loans={loan_loadable.contents}
      />
      <Box mt={1}>
        <MatchingAgentSection proposalMatchings={proposalMatchings} />
      </Box>
      <Box mt={1}>
        <TaxReturnSection />
      </Box>
    </MypageTemplate>
  );
};
