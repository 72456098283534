import { makeStyles, Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import * as React from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { MonthlyCashflowResult } from "../../../../../../api/generated";
import { PropallyApiFactory } from "../../../../../../module/custom_api_factory";
import { CustomForm } from "../../../../../../scripts/components/renewal_v1/form";
import { CustomSelector } from "../../../../../../scripts/components/renewal_v1/selecter";
import { parseFormattedNumber } from "../../../../../../utilities/renewal_v1/format";
import { PropertyManagementDetailSnackbarState, useRequestInitialLoading } from "../../../../../../view_models/atoms";
import { SelectedProperty } from "../../../../../../view_models/property_selectors";

import { loanType } from "./history";

const useStyles = makeStyles(() => ({
  textLink: {
    width: 80,
    textAlign: "center",
  },
}));

interface props {
  monthlyCashflowResult?: MonthlyCashflowResult;
  loanType: loanType;
  onCancel: () => void;
  onSave?: () => void;
  onCreate?: () => void;
}

const LoanPrepaymentMethodOptions = [
  { value: "", label: "未選択" },
  { value: "ReduceLoanAmount", label: "返済額軽減型" },
  { value: "ReduceLoanPaymentPeriod", label: "期間短縮型" },
];

// 履歴編集用コンポーネント
export const LoanInfoHistoryEditor: React.FC<props> = ({
  monthlyCashflowResult,
  loanType,
  onCancel,
  onSave,
  onCreate,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { control, errors, getValues } = useForm<any>();
  const requestInitialLoading = useRequestInitialLoading();
  // 選択済み物件取得
  const selected_property = useRecoilValue(SelectedProperty);
  // 物件詳細でのスナックバー表示
  const setManagementDetailSnackBarState = useSetRecoilState(PropertyManagementDetailSnackbarState);

  const fp = PropallyApiFactory();

  const onSubmit = async () => {
    const values = getValues();
    const sendData = {
      ...values,
      month: `${values.month}-01`,
      ...(values.loan_prepayment && {
        loan_prepayment: parseFormattedNumber(values.loan_prepayment),
      }),
    };

    try {
      const result = await fp.v1PropertiesPropertyIdMonthlyCashflowResultsPatch(selected_property.id, sendData, {
        withCredentials: true,
      });
      if (result.status === 200) {
        requestInitialLoading();
      }
    } catch (e) {
      console.error(e);
      setManagementDetailSnackBarState({
        message: "物件情報を変更できませんでした",
        status: "error",
        isOpen: true,
      });
      history.push("/management/detail");
    }
  };

  const handleSave = async () => {
    if (onSave) {
      // エラーの場合エディターの非表示後、詳細ページに戻るためこの順序
      onSave();
      await onSubmit();
    } else if (onCreate) {
      await onSubmit();
      onCreate();
    }
  };

  return (
    <Box>
      <Box mb={2}>
        <Controller
          name="month"
          control={control}
          rules={{ required: "入力してください。" }}
          defaultValue={dayjs(monthlyCashflowResult?.month).format("YYYY-MM") || ""}
          render={({ ...field }) => (
            <CustomForm
              {...field}
              label="支払開始月"
              required
              type="month"
              error={!!errors.month}
              errorText={errors.month?.message}
            />
          )}
        />
        {loanType === "借入利率変動" && (
          <Controller
            name="loan_interest_rate_percentage"
            control={control}
            rules={{ required: "入力してください。" }}
            defaultValue={monthlyCashflowResult?.loan_interest_rate_percentage || ""}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="借入利率 (年利)"
                required
                error={!!errors.loan_interest_rate_percentage}
                errorText={errors.loan_interest_rate_percentage?.message}
                placeHolder="10"
                unitSuffix="%"
                isShort
              />
            )}
          />
        )}
        {loanType === "繰上返済" && (
          <>
            <Controller
              name="loan_prepayment"
              control={control}
              rules={{ required: "入力してください。" }}
              defaultValue={monthlyCashflowResult?.loan_prepayment || ""}
              render={({ ...field }) => (
                <CustomForm
                  {...field}
                  label="返済金額"
                  required
                  error={!!errors.loan_prepayment}
                  errorText={errors.loan_prepayment?.message}
                  placeHolder="100,000,000"
                  unitSuffix="円"
                  formatNumeric
                />
              )}
            />
            <Controller
              name="loan_prepayment_method"
              control={control}
              rules={{
                required: "選択してください。",
              }}
              defaultValue={monthlyCashflowResult?.loan_prepayment_method || ""}
              render={({ ...field }) => (
                <CustomSelector
                  {...field}
                  label="返済方式"
                  required
                  onChange={field.onChange}
                  error={!!errors.loan_prepayment_method}
                  errorText={errors.loan_prepayment_method?.message}
                  options={LoanPrepaymentMethodOptions}
                />
              )}
            />
          </>
        )}
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Typography variant="button" color="primary" className={classes.textLink} onClick={onCancel}>
          キャンセル
        </Typography>
        <Typography variant="button" color="primary" className={classes.textLink} onClick={handleSave}>
          保存
        </Typography>
      </Box>
    </Box>
  );
};
