import { useRecoilValueLoadable } from "recoil";

import { MonthlyCashflowResultsOnSelectedPeriodWithYearlySum } from "../../../../view_models/balancesheet_selectors";

interface Row {
  title: string;
  values: string[];
  type: "category" | "item" | "sum";
}

export const useProfitAndLossSheetData = () => {
  const loadable = useRecoilValueLoadable(MonthlyCashflowResultsOnSelectedPeriodWithYearlySum);

  if (loadable.state !== "hasValue") return { data: [], isLoading: true };

  const monthly_results_on_selected_period = loadable.contents;

  const data: Row[] = [
    {
      title: "賃貸料",
      values: monthly_results_on_selected_period.map(() => {
        return "";
      }),
      type: "category",
    },
    {
      title: "家賃収入",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.rent_income).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "管理費収入",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.management_fee_income).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "賃貸料",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.rent_income + r.result.management_fee_income).toLocaleString()}円`;
      }),
      type: "sum",
    },
    {
      title: "礼金・権利金・更新料",
      values: monthly_results_on_selected_period.map(() => {
        return "";
      }),
      type: "category",
    },
    {
      title: "礼金",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.key_money).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "更新料",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.security_deposit).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "礼金・権利金・更新料",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.key_money + r.result.security_deposit).toLocaleString()}円`;
      }),
      type: "sum",
    },
    {
      title: "収入金額",
      values: monthly_results_on_selected_period.map(() => {
        return "";
      }),
      type: "category",
    },
    {
      title: "収入金額計",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(
          r.result.rent_income + r.result.management_fee_income + r.result.key_money + r.result.security_deposit
        ).toLocaleString()}円`;
      }),
      type: "sum",
    },
    {
      title: "必要経費",
      values: monthly_results_on_selected_period.map(() => {
        return "";
      }),
      type: "category",
    },
    // 固定資産税・都市計画税
    {
      title: "租税公課",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.property_tax + r.property_acquisition_tax).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "損害保険料",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(
          (r.result.fire_insurance_distributed_price || 0) + (r.result.earthquake_insurance_distributed_price || 0)
        ).toLocaleString()}円`;
      }),
      type: "item",
    },
    // 修繕積立金、設備交換・大規模修繕費用
    {
      title: "修繕費",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.repair_fund_cost + r.result.large_scale_repair_fund_cost).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "減価償却費",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(
          (r.result.monthly_building_depreciation || 0) + (r.result.monthly_facilities_depreciation || 0)
        ).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "借入金利子",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.loan_interest).toLocaleString()}円`;
      }),
      type: "item",
    },
    // 賃貸管理費、新規賃貸契約手数料、賃貸契約更新時手数料
    {
      title: "賃貸管理費",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(
          r.result.management_cost +
            r.result.outsourcing_fee_of_new_lease_contract +
            r.result.outsourcing_fee_of_updating_lease_contract
        ).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "建物管理費",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.building_management_cost).toLocaleString()}円`;
      }),
      type: "item",
    },
    // その他経費、その他一過性の支出、購入時諸費用
    {
      title: "その他経費",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(
          r.result.misc_cost + r.result.misc_onetime_cost + r.price_of_misc_expences_at_bought
        ).toLocaleString()}円`;
      }),
      type: "item",
    },

    {
      title: "必要経費計",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(
          r.result.property_tax +
            r.property_acquisition_tax +
            (r.result.fire_insurance_distributed_price || 0) +
            (r.result.earthquake_insurance_distributed_price || 0) +
            r.result.repair_fund_cost +
            r.result.large_scale_repair_fund_cost +
            (r.result.monthly_building_depreciation || 0) +
            (r.result.monthly_facilities_depreciation || 0) +
            r.result.loan_interest +
            r.result.management_cost +
            r.result.outsourcing_fee_of_new_lease_contract +
            r.result.outsourcing_fee_of_updating_lease_contract +
            r.result.building_management_cost +
            r.result.misc_cost +
            r.result.misc_onetime_cost +
            r.price_of_misc_expences_at_bought
        ).toLocaleString()}円`;
      }),
      type: "sum",
    },

    {
      title: "差引金額",
      values: monthly_results_on_selected_period.map(() => {
        return "";
      }),
      type: "category",
    },
    {
      title: "差引金額（収入金額－必要経費）",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.real_estate_income_before_deduction_of_blue_returns || 0).toLocaleString()}円`;
      }),
      type: "sum",
    },
    {
      title: "不動産所得",
      values: monthly_results_on_selected_period.map(() => {
        return "";
      }),
      type: "category",
    },
    {
      title: "不動産所得",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.real_estate_income_before_deduction_of_blue_returns || 0).toLocaleString()}円`;
      }),
      type: "sum",
    },
  ];

  return { data, isLoading: false, monthlyResults: loadable.contents };
};
