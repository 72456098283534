import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { useHistory } from "react-router";
import { useRecoilValue } from "recoil";

import { Document, DocumentType } from "../../api/generated/api";
import { PropallyApiFactory } from "../../module/custom_api_factory";
import { PropertyOperationTemplate } from "../../scripts/components/renewal_v1/template/property_operation_template";
import { SelectedProperty } from "../../view_models/property_selectors";
import { AccordionDetailItemRow } from "../renewal_v1/management/management_detail_tabs/accordion_detail_item_row";
import { DocumentTypeOption } from "../renewal_v1/management/types";

const documentTypeOptions: DocumentTypeOption[] = [
  {
    value: DocumentType.NUMBER_0,
    label: "マイソク・物件オファー資料",
  },
  {
    value: DocumentType.NUMBER_1,
    label: "物件パンフレット",
  },
  {
    value: DocumentType.NUMBER_2,
    label: "売買契約書",
  },
  {
    value: DocumentType.NUMBER_3,
    label: "重要事項説明書",
  },
  {
    value: DocumentType.NUMBER_4,
    label: "金銭消費貸借契約書",
  },
  {
    value: DocumentType.NUMBER_5,
    label: "返済予定表",
  },
  {
    value: DocumentType.NUMBER_6,
    label: "賃貸借契約書",
  },
  {
    value: DocumentType.NUMBER_7,
    label: "賃貸管理委託契約書",
  },
  {
    value: DocumentType.NUMBER_8,
    label: "保険証券",
  },
  {
    value: DocumentType.NUMBER_9,
    label: "納税通知書（固定資産税・都市計画税）",
  },
  {
    value: DocumentType.NUMBER_10,
    label: "納税通知書（不動産取得税）",
  },
  {
    value: DocumentType.NUMBER_11,
    label: "その他",
  },
];

export const PropertyDocumentList: React.FC = () => {
  const [documents, setDocuments] = React.useState<Map<DocumentType, Document[]>>(null);
  const history = useHistory();
  const property = useRecoilValue(SelectedProperty);

  const onClickPageBack = () => {
    history.push("/management/detail");
  };

  // ドキュメントの一覧取得
  const getDocuments = async () => {
    const fp = PropallyApiFactory();
    try {
      const newDocuments = new Map();
      await Promise.all(
        documentTypeOptions.map(async (documentType) => {
          const { data } = await fp.v1PropertiesPropertyIdDocumentsDocumentTypeFilesGet(
            property.id,
            documentType.value,
            {
              withCredentials: true,
            }
          );
          newDocuments.set(documentType.value, data);
        })
      );
      setDocuments(newDocuments);
    } catch (e) {
      console.error(e);
      return;
    }
  };

  React.useEffect(() => {
    // ドキュメントの取得
    if (!documents) {
      getDocuments();
    }
  }, [property.id]);

  return (
    <PropertyOperationTemplate title={"物件書類"} onBack={onClickPageBack}>
      <Box p={2}>
        <Box display="flex" justifyContent="center">
          <Typography variant="body1" color="textSecondary" paragraph style={{ textAlign: "center" }}>
            書類をアップロードして、確定申告などの時に必要な書類を確認できるようにしておきましょう。
            <br />
            アップロードした書類はいつでも簡単で、再度ダウンロードすることも可能です。
          </Typography>
        </Box>
        <Box>
          <Divider />
          <AccordionDetailItemRow
            title="物件パンフレット"
            isDocument
            hasDocument={documents?.get(DocumentType.NUMBER_1)?.length > 0}
            documentType={DocumentType.NUMBER_1}
          />
          <Divider />
          <AccordionDetailItemRow
            title="売買契約書"
            isDocument
            hasDocument={documents?.get(DocumentType.NUMBER_2)?.length > 0}
            documentType={DocumentType.NUMBER_2}
          />
          <Divider />
          <AccordionDetailItemRow
            title="重要事項説明書"
            isDocument
            hasDocument={documents?.get(DocumentType.NUMBER_3)?.length > 0}
            documentType={DocumentType.NUMBER_3}
          />
          <Divider />
          <AccordionDetailItemRow
            title="賃貸借契約書"
            isDocument
            hasDocument={documents?.get(DocumentType.NUMBER_6)?.length > 0}
            documentType={DocumentType.NUMBER_6}
          />
          <Divider />
          <AccordionDetailItemRow
            title="賃貸借管理委託契約書"
            isDocument
            hasDocument={documents?.get(DocumentType.NUMBER_7)?.length > 0}
            documentType={DocumentType.NUMBER_7}
          />
          <Divider />
          <AccordionDetailItemRow
            title="返済予定表"
            isDocument
            hasDocument={documents?.get(DocumentType.NUMBER_5)?.length > 0}
            documentType={DocumentType.NUMBER_5}
          />
          <Divider />
          <AccordionDetailItemRow
            title="納税通知書(固都税/不動産取得税)"
            isDocument
            hasDocument={documents?.get(DocumentType.NUMBER_10)?.length > 0}
            documentType={DocumentType.NUMBER_10}
          />
          <Divider />
          <AccordionDetailItemRow
            title="保険証書(火災/地震)"
            isDocument
            hasDocument={documents?.get(DocumentType.NUMBER_8)?.length > 0}
            documentType={DocumentType.NUMBER_8}
          />
          <Divider />
          <AccordionDetailItemRow
            title="その他（領収書など）"
            isDocument
            hasDocument={documents?.get(DocumentType.NUMBER_11)?.length > 0}
            documentType={DocumentType.NUMBER_11}
          />
          <Divider />
        </Box>
      </Box>
    </PropertyOperationTemplate>
  );
};
