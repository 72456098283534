import { useRecoilValue, useRecoilValueLoadable } from "recoil";

import { UserState } from "../../../../view_models/atoms";
import { MonthlyCashflowResultsOnSelectedPeriodWithYearlySum } from "../../../../view_models/balancesheet_selectors";

interface Row {
  title: string;
  values: string[];
  type: "category" | "item" | "sum";
}

export const useBalanceSheetData = () => {
  const loadable = useRecoilValueLoadable(MonthlyCashflowResultsOnSelectedPeriodWithYearlySum);
  const user = useRecoilValue(UserState);

  if (loadable.state !== "hasValue") return { data: [], isLoading: true };

  const monthly_results_on_selected_period = loadable.contents;

  const data: Row[] = [
    {
      title: "毎月の収入",
      values: monthly_results_on_selected_period.map(() => {
        return "";
      }),
      type: "category",
    },
    {
      title: "家賃収入",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.rent_income).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "管理費収入",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.management_fee_income).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "毎月の収入合計",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.rent_income + r.result.management_fee_income).toLocaleString()}円`;
      }),
      type: "sum",
    },
    {
      title: "毎月の支出",
      values: monthly_results_on_selected_period.map(() => {
        return "";
      }),
      type: "category",
    },
    {
      title: "賃貸管理費",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.management_cost).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "建物管理費",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.building_management_cost).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "修繕積立金",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.repair_fund_cost).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "その他経費",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.misc_cost).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "毎月の支出合計",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(
          r.result.management_cost + r.result.building_management_cost + r.result.repair_fund_cost + r.result.misc_cost
        ).toLocaleString()}円`;
      }),
      type: "sum",
    },
    {
      title: "毎月の収支",
      values: monthly_results_on_selected_period.map(() => {
        return "";
      }),
      type: "category",
    },
    {
      title: "毎月の収支（ローン返済前）",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(
          r.result.rent_income +
            r.result.management_fee_income -
            (r.result.management_cost +
              r.result.building_management_cost +
              r.result.repair_fund_cost +
              r.result.misc_cost)
        ).toLocaleString()}円`;
      }),
      type: "sum",
    },
    {
      title: "ローン",
      values: monthly_results_on_selected_period.map(() => {
        return "";
      }),
      type: "category",
    },
    {
      title: "利息",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.loan_interest).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "元金",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.loan_principal).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "繰上返済",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.loan_prepayment + r.loan_prepayment_by_selling).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "ローン返済（利息含む）",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(
          r.result.loan_interest + r.result.loan_principal + r.result.loan_prepayment + r.loan_prepayment_by_selling
        ).toLocaleString()}円`;
      }),
      type: "sum",
    },
    {
      title: "毎月の収支（ローン返済後）",
      values: monthly_results_on_selected_period.map(() => {
        return "";
      }),
      type: "category",
    },
    {
      title: "毎月の収支合計（ローン返済後）",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(
          r.result.rent_income +
            r.result.management_fee_income -
            (r.result.management_cost +
              r.result.building_management_cost +
              r.result.repair_fund_cost +
              r.result.misc_cost) -
            (r.result.loan_interest + r.result.loan_principal + r.result.loan_prepayment + r.loan_prepayment_by_selling)
        ).toLocaleString()}円`;
      }),
      type: "sum",
    },
    {
      title: "その他の収入",
      values: monthly_results_on_selected_period.map(() => {
        return "";
      }),
      type: "category",
    },
    {
      title: "礼金",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.key_money).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "更新料",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.security_deposit).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "売却による収入",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.sales_amount_by_selling).toLocaleString()}円`;
      }),
      type: "item",
    },

    {
      title: "その他の収入合計",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(
          r.result.key_money + r.result.security_deposit + r.sales_amount_by_selling
        ).toLocaleString()}円`;
      }),
      type: "sum",
    },
    {
      title: "その他の支出",
      values: monthly_results_on_selected_period.map(() => {
        return "";
      }),
      type: "category",
    },
    {
      title: "新規賃貸契約手数料",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.outsourcing_fee_of_new_lease_contract).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "賃貸契約更新時手数料",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.outsourcing_fee_of_updating_lease_contract).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "固定資産税・都市計画税",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.property_tax).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "火災保険料",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.fire_insurance_cost).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "地震保険料",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.earthquake_insurance_cost).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "設備修繕・大規模修繕費用",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.large_scale_repair_fund_cost).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "その他一過性の支出",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.result.misc_onetime_cost).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "売却コスト想定額",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.expenses_by_selling).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "その他の支出合計",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(
          r.result.outsourcing_fee_of_new_lease_contract +
            r.result.outsourcing_fee_of_updating_lease_contract +
            r.result.property_tax +
            r.result.fire_insurance_cost +
            r.result.earthquake_insurance_cost +
            r.result.large_scale_repair_fund_cost +
            r.result.misc_onetime_cost +
            r.expenses_by_selling
        ).toLocaleString()}円`;
      }),
      type: "sum",
    },
    {
      title: "頭金・購入時諸費用",
      values: monthly_results_on_selected_period.map(() => {
        return "";
      }),
      type: "category",
    },
    {
      title: "購入価格",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.price_of_properties).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "借入金額",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.price_of_loan).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "不動産取得税",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.property_acquisition_tax).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "購入時諸費用",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(r.price_of_misc_expences_at_bought).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "頭金・購入時諸費用合計",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(
          r.price_of_properties - r.price_of_loan + r.price_of_misc_expences_at_bought + r.property_acquisition_tax
        ).toLocaleString()}円`;
      }),
      type: "sum",
    },
    {
      title: "収支（税前）",
      values: monthly_results_on_selected_period.map(() => {
        return "";
      }),
      type: "category",
    },
    {
      title: "収支合計（税前）",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(
          // 毎月の収支
          r.result.rent_income +
            r.result.management_fee_income -
            (r.result.management_cost +
              r.result.building_management_cost +
              r.result.repair_fund_cost +
              r.result.misc_cost) -
            // ローン
            (r.result.loan_interest +
              r.result.loan_principal +
              r.result.loan_prepayment +
              r.loan_prepayment_by_selling) +
            // その他収支
            (r.result.key_money +
              r.result.security_deposit +
              r.sales_amount_by_selling -
              // その他支出
              (r.result.outsourcing_fee_of_new_lease_contract +
                r.result.outsourcing_fee_of_updating_lease_contract +
                r.result.property_tax +
                r.result.fire_insurance_cost +
                r.result.earthquake_insurance_cost +
                r.result.large_scale_repair_fund_cost +
                r.result.misc_onetime_cost +
                r.expenses_by_selling) -
              // 購入時費用
              (r.price_of_properties -
                r.price_of_loan +
                r.price_of_misc_expences_at_bought +
                r.property_acquisition_tax))
        ).toLocaleString()}円`;
      }),
      type: "sum",
    },
    {
      title: "不動産所得関連",
      values: monthly_results_on_selected_period.map(() => {
        return "";
      }),
      type: "category",
    },
    {
      title: "不動産所得にかかる税金",
      values: monthly_results_on_selected_period.map((r) => {
        return user.income_tax_rate
          ? `${Math.round(r.result.tax_on_real_estates).toLocaleString()}円`
          : "「シミュレーション」画面から所得税率を設定してください";
      }),
      type: "sum",
    },
    {
      title: "収支（税後）",
      values: monthly_results_on_selected_period.map(() => {
        return "";
      }),
      type: "category",
    },
    {
      title: "収支合計（税後）",
      values: monthly_results_on_selected_period.map((r) => {
        return user.income_tax_rate
          ? `${Math.round(
              // 毎月の収支
              r.result.rent_income +
                r.result.management_fee_income -
                (r.result.management_cost +
                  r.result.building_management_cost +
                  r.result.repair_fund_cost +
                  r.result.misc_cost) -
                // ローン
                (r.result.loan_interest +
                  r.result.loan_principal +
                  r.result.loan_prepayment +
                  r.loan_prepayment_by_selling) +
                // その他収支
                (r.result.key_money +
                  r.result.security_deposit +
                  r.sales_amount_by_selling -
                  // その他支出
                  (r.result.outsourcing_fee_of_new_lease_contract +
                    r.result.outsourcing_fee_of_updating_lease_contract +
                    r.result.property_tax +
                    r.result.fire_insurance_cost +
                    r.result.earthquake_insurance_cost +
                    r.result.large_scale_repair_fund_cost +
                    r.result.misc_onetime_cost +
                    r.expenses_by_selling) -
                  // 購入時費用
                  (r.price_of_properties -
                    r.price_of_loan +
                    r.price_of_misc_expences_at_bought +
                    r.property_acquisition_tax)) -
                r.result.tax_on_real_estates
            ).toLocaleString()}円`
          : "「シミュレーション」画面から所得税率を設定してください";
      }),
      type: "sum",
    },
    {
      title: "累積収支（税前）",
      values: monthly_results_on_selected_period.map(() => {
        return "";
      }),
      type: "category",
    },
    {
      title: "累積収入（税前）",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(
          // 家賃＋管理費＋礼金＋更新料＋売却による収入
          r.cumulative_rent_income + r.cumulative_misc_income + r.cumulative_sales_amount_by_selling
        ).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "累積支出（税前）",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(
          r.cumulative_expence +
            r.cumulative_loan_payment +
            r.cumulative_misc_expence +
            r.cumulative_expences_at_buying +
            r.cumulative_misc_expences_at_buying +
            r.cumulative_property_acquisition_tax +
            r.cumulative_loan_prepayment_by_selling +
            r.cumulative_expenses_by_selling
        ).toLocaleString()}円`;
      }),
      type: "item",
    },
    {
      title: "累積収支（税前）",
      values: monthly_results_on_selected_period.map((r) => {
        return `${Math.round(
          r.cumulative_rent_income +
            r.cumulative_misc_income +
            r.cumulative_sales_amount_by_selling -
            (r.cumulative_expence +
              r.cumulative_loan_payment +
              r.cumulative_loan_prepayment_by_selling +
              r.cumulative_misc_expence +
              r.cumulative_expences_at_buying +
              r.cumulative_misc_expences_at_buying +
              r.cumulative_property_acquisition_tax +
              r.cumulative_expenses_by_selling)
        ).toLocaleString()}円`;
      }),
      type: "sum",
    },
    {
      title: "累積収支（税後）",
      values: monthly_results_on_selected_period.map(() => {
        return "";
      }),
      type: "category",
    },
    {
      title: "不動産所得にかかる税金の累積",
      values: monthly_results_on_selected_period.map((r) => {
        return user.income_tax_rate
          ? `${Math.round(r.cumulative_real_estate_income_taxes).toLocaleString()}円`
          : "「シミュレーション」画面から所得税率を設定してください";
      }),
      type: "item",
    },
    {
      title: "累積収支（税後）",
      values: monthly_results_on_selected_period.map((r) => {
        return user.income_tax_rate
          ? `${Math.round(
              r.cumulative_rent_income +
                r.cumulative_misc_income +
                r.cumulative_sales_amount_by_selling -
                (r.cumulative_expence +
                  r.cumulative_loan_payment +
                  r.cumulative_loan_prepayment_by_selling +
                  r.cumulative_misc_expence +
                  r.cumulative_expences_at_buying +
                  r.cumulative_misc_expences_at_buying +
                  r.cumulative_property_acquisition_tax +
                  r.cumulative_expenses_by_selling) -
                r.cumulative_real_estate_income_taxes
            ).toLocaleString()}円`
          : "「シミュレーション」画面から所得税率を設定してください";
      }),
      type: "sum",
    },
  ];

  return { data, isLoading: false, monthlyResults: loadable.contents };
};
