import { Box, Divider, Modal, Typography, makeStyles } from "@material-ui/core";
import * as React from "react";

import { StyledModal } from "../styled_modal";

const useStyles = makeStyles((theme) => ({
  messageContent: {
    padding: "24px 24px 0 24px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    borderTop: "1px solid #ccc",
    alignItems: "center",
    height: 60,
  },
  button: {
    minWidth: 100,
  },
  description: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
    textAlign: "center",
    "&:hover": {
      borderBottom: "none",
    },
  },
  divider: {
    height: "100%",
    backgroundColor: theme.palette.divider,
    margin: "0 16px",
  },
}));

interface DeleteImageModalProps {
  onClose: () => void;
  onClickDelete: (document: Document) => Promise<void>;
  // onClickDelete: () => void;
  onClickCancel: () => void;
  isOpen: boolean;
}
export const DeleteImageModal: React.FC<DeleteImageModalProps> = ({
  onClose,
  onClickDelete,
  onClickCancel,
  isOpen,
}) => {
  const classes = useStyles();
  const handleClick = React.useCallback(() => {
    document.body.style.overflow = "auto"; // Enable scrolling
    onClose();
  }, [onClose]);

  // スクロール制御
  React.useEffect(() => {
    document.body.style.overflow = "hidden"; // Disable scroll when modal is open
    return () => {
      document.body.style.overflow = "auto"; // Enable scroll when modal is unmounted
    };
  }, []);

  const handleCancel = React.useCallback(() => {
    onClickCancel();
    onClose();
  }, []);
  const handleDelete = React.useCallback(() => {
    onClickDelete();
    onClose();
  }, []);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <StyledModal onClickOutside={handleClick}>
        <Box className={classes.messageContent}>
          <Box textAlign="center" mb={2}>
            <Typography variant="h3">本当に削除しますか？</Typography>
          </Box>
          <Typography className={classes.description}>
            削除した書類は復元できません。
            <br /> 本当に削除しますか？
          </Typography>
        </Box>
        <Box className={classes.buttonContainer}>
          <Typography
            variant="button"
            color="textPrimary"
            className={`${classes.link} ${classes.button}`}
            onClick={handleCancel}
          >
            キャンセル
          </Typography>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <Typography
            variant="button"
            color="error"
            className={`${classes.link} ${classes.button}`}
            onClick={handleDelete}
          >
            削除する
          </Typography>
        </Box>
      </StyledModal>
    </Modal>
  );
};
