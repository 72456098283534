import { makeStyles, Box } from "@material-ui/core";
import dayjs from "dayjs";
import * as React from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router";
import { useSetRecoilState } from "recoil";

import { PropallyApiFactory } from "../../../../../module/custom_api_factory";
import { CustomForm } from "../../../../../scripts/components/renewal_v1/form";
import { PropertyOperationTemplate } from "../../../../../scripts/components/renewal_v1/template/property_operation_template";
import { DatePickerComponent } from "../../../../../scripts/components/renewal_v1/year_month_picker";
import { parseFormattedNumber } from "../../../../../utilities/renewal_v1/format";
import {
  PropertyHolder,
  PropertyManagementDetailSnackbarState,
  useRequestInitialLoading,
} from "../../../../../view_models/atoms";
const useStyles = makeStyles((theme) => ({
  content: {
    width: 640,
    [theme.breakpoints.down("sm")]: {
      width: 342,
    },
    margin: "16px auto 0px auto",
  },
}));

interface EditPropertySectionPurchaseInfoProps {
  propertyHolder: PropertyHolder;
  onClickPageBack: () => void;
}

export const EditPropertySectionPurchaseInfo: React.FC<EditPropertySectionPurchaseInfoProps> = ({
  propertyHolder,
  onClickPageBack,
}) => {
  const classes = useStyles();
  const { control, errors, watch } = useForm({
    defaultValues: {
      bought_at: propertyHolder.property.bought_at
        ? dayjs(propertyHolder.property.bought_at).format("YYYY/MM/DD")
        : dayjs(new Date()).format("YYYY/MM/DD"),
      total_price_of_property: propertyHolder.property.total_price_of_property,
      priceof_tax: propertyHolder.property.priceof_tax ? propertyHolder.property.priceof_tax : "",
      priceof_building: propertyHolder.property.priceof_building ? propertyHolder.property.priceof_building : "",
      priceof_land: propertyHolder.property.priceof_land ? propertyHolder.property.priceof_land : "",
      priceof_facilities: propertyHolder.property.priceof_facilities ? propertyHolder.property.priceof_facilities : "",
      property_acquisition_tax: propertyHolder.property.property_acquisition_tax
        ? propertyHolder.property.property_acquisition_tax
        : "",
      property_acquisition_tax_payment_month: propertyHolder.property.property_acquisition_tax_payment_month
        ? dayjs(propertyHolder.property.property_acquisition_tax_payment_month).format("YYYY/MM")
        : dayjs(new Date()).format("YYYY/MM"),
      misc_expences_at_bought: propertyHolder.property.misc_expences_at_bought
        ? propertyHolder.property.misc_expences_at_bought
        : "",
    },
  });
  const history = useHistory();
  const requestInitialLoading = useRequestInitialLoading();
  // 物件詳細でのスナックバー表示
  const setManagementDetailSnackBarState = useSetRecoilState(PropertyManagementDetailSnackbarState);

  const watchValues = watch();

  /**
   * useEffect
   */
  const propertyInfo = React.useMemo(() => {
    return {
      bought_at: watchValues.bought_at,
      total_price_of_property: parseFormattedNumber(watchValues.total_price_of_property),
      priceof_tax: parseFormattedNumber(watchValues.priceof_tax),
      priceof_building: parseFormattedNumber(watchValues.priceof_building),
      priceof_land: parseFormattedNumber(watchValues.priceof_land),
      priceof_facilities: parseFormattedNumber(watchValues.priceof_facilities),
      property_acquisition_tax: parseFormattedNumber(watchValues.property_acquisition_tax),
      property_acquisition_tax_payment_month: watchValues.property_acquisition_tax_payment_month,
      misc_expences_at_bought: parseFormattedNumber(watchValues.misc_expences_at_bought),
    };
  }, [watchValues]);

  const propertyState = React.useMemo(() => {
    return {
      ...propertyHolder.property,
      ...propertyInfo,
    };
  }, [propertyInfo]);

  /*
   関数定義
  */
  const fp = PropallyApiFactory();
  const handleOnSave = async () => {
    try {
      const data = await fp.v1PropertiesPropertyIdPatch(propertyHolder.property.id, propertyState, {
        withCredentials: true,
      });
      if (data.status === 200) {
        setManagementDetailSnackBarState({
          message: "物件情報を変更しました",
          status: "success",
          isOpen: true,
        });
        requestInitialLoading();
      }
    } catch (e) {
      console.error(e);
      const errorMessage = e.response?.data?.message?.includes("invalid_total_price_of_property")
        ? "「購入価格 = うち消費税 + うち建物代 + うち土地代 + 建物付属設備代」となっていません"
        : "物件情報を変更できませんでした";

      setManagementDetailSnackBarState({
        message: errorMessage,
        status: "error",
        isOpen: true,
      });
    }
    history.push("/management/detail");
  };

  return (
    <PropertyOperationTemplate title="購入時情報" onBack={onClickPageBack} onSave={handleOnSave}>
      <Box>
        <Box className={classes.content}>
          {/* 購入時期 */}
          <Controller
            name="bought_at"
            control={control}
            rules={{ required: true }}
            render={({ ...field }) => <DatePickerComponent label="購入時期" {...field} />}
          />
          {/* 購入価格 */}
          <Controller
            name="total_price_of_property"
            control={control}
            rules={{ required: "入力してください。" }}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="購入価格（税込）"
                required
                error={!!errors.total_price_of_property}
                errorText={errors.total_price_of_property?.message}
                placeHolder="100,000,000"
                unitSuffix="円"
                formatNumeric
              />
            )}
          />
          {/* うち消費税 */}
          <Controller
            name="priceof_tax"
            control={control}
            rules={{ required: "入力してください。" }}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="うち消費税"
                required
                error={!!errors.priceof_tax}
                errorText={errors.priceof_tax?.message}
                placeHolder="100,000,000"
                unitSuffix="円"
                isShort
                formatNumeric
              />
            )}
          />
          {/* うち建物代 */}
          <Controller
            name="priceof_building"
            control={control}
            rules={{ required: "入力してください。" }}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="うち建物代"
                required
                error={!!errors.priceof_building}
                errorText={errors.priceof_building?.message}
                placeHolder="100,000,000"
                unitSuffix="円"
                formatNumeric
              />
            )}
          />

          {/* うち土地代 */}
          <Controller
            name="priceof_land"
            control={control}
            rules={{ required: "入力してください。" }}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="うち土地代"
                required
                error={!!errors.priceof_land}
                errorText={errors.priceof_land?.message}
                placeHolder="100,000,000"
                unitSuffix="円"
                formatNumeric
              />
            )}
          />

          {/* 建物付属設備代（税抜） */}
          <Controller
            name="priceof_facilities"
            control={control}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="建物付属設備代（税抜）"
                error={!!errors.priceof_facilities}
                errorText={errors.priceof_facilities?.message}
                placeHolder="100,000,000"
                unitSuffix="円"
                formatNumeric
              />
            )}
          />
          {/* 購入時不動産取得税 */}
          <Controller
            name="property_acquisition_tax"
            control={control}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="購入時不動産取得税"
                error={!!errors.property_acquisition_tax}
                errorText={errors.property_acquisition_tax?.message}
                placeHolder="100,000,000"
                unitSuffix="円"
                formatNumeric
              />
            )}
          />
          {/* 不動産取得税の支払い月 */}
          <Controller
            name="property_acquisition_tax_payment_month"
            control={control}
            rules={{ required: true }}
            render={({ ...field }) => <DatePickerComponent label="不動産取得税の支払い月" {...field} />}
          />
          {/* 購入時その他諸費用  */}
          <Controller
            name="misc_expences_at_bought"
            control={control}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="購入時その他諸費用"
                error={!!errors.misc_expences_at_bought}
                errorText={errors.misc_expences_at_bought?.message}
                placeHolder="100,000,000"
                unitSuffix="円"
                formatNumeric
              />
            )}
          />
        </Box>
      </Box>
    </PropertyOperationTemplate>
  );
};
