import { Box, IconButton, Input, Theme, Typography, makeStyles } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DescriptionIcon from "@material-ui/icons/Description";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import * as React from "react";
import { useHistory } from "react-router";

import PropertySampleImage01 from "../../../../../assets/image/property_sample_image01.png";
import PropertySampleImage02 from "../../../../../assets/image/property_sample_image02.png";
import PropertySampleImage03 from "../../../../../assets/image/property_sample_image03.png";
import {
  getActualYieldRateByCashflowResult,
  getPropertyAddressString,
  getYieldRate,
} from "../../../../utilities/get_property_address_string";
import {
  getLatestMonthlyFeeFromProperty,
  isPropertyCurrentInUse,
} from "../../../../utilities/renewal_v1/calculate_property";
import { useDevice } from "../../../../utilities/sp/use_device";
import { PropertyHolder } from "../../../../view_models/atoms";
import { MonthlyBalanceSheet } from "../../../../view_models/balancesheet_selectors";
import { CustomChip } from "../chip";
import { DropDownMenu, DropDownMenuItem } from "../dropdown_menu";
import { NavigationPath } from "../enums";
import Footer from "../footer";
import NavigationBar from "../navigation";
import { OccupancyStatus } from "../property/occupancy_status";
import theme from "../theme";

import { MainTemplate } from "./main_template";

// imageが空の場合は画像3枚からランダムで表示
const sampleImages = [PropertySampleImage01, PropertySampleImage02, PropertySampleImage03];

const useStyles = makeStyles<Theme, Props>(() => ({
  background: {
    backgroundColor: theme.palette.background.paper,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  media: {
    height: 240,
    backgroundSize: "cover",
    // backgroundImage: `url(${PropertySampleImage})`,
    backgroundImage: (props: Props) => {
      if (props.propertyHolder.cover_image_url !== "/assets/image/NoImage.svg") {
        return `url(${props.propertyHolder.cover_image_url})`;
      }
      return `url(${sampleImages[Math.floor(Math.random() * sampleImages.length)]})`;
    },
    backgroundPosition: "center",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      height: 320,
      backgroundPosition: "center center",
    },
  },
  iconContainer: {
    width: 800,
    display: "flex",
    margin: "20px auto 0 auto",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0 24px",
      margin: "44px auto 0 auto",
    },
  },
  iconButton: {
    color: theme.palette.common.white,
  },
  iconRoot: {
    padding: 0,
  },
  labelMenuIcon: {
    backgroundColor: theme.palette.transparent,
    borderRadius: "50%",
    color: theme.palette.background.paper, // 文字色を白に設定
    "&:hover": {
      backgroundColor: theme.palette.grey[900],
    },
  },
  menuButton: {
    color: theme.palette.common.white,
  },
  menuItem: {
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  propertyInfo: {
    width: 640,
    padding: "32px 0px 40px 0px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "16px 24px 24px 24px",
    },
  },
  propertyInfoDetail: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
  },
  propertyInfoDetailText: {
    display: "flex",
    marginRight: 24,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
}));

type Props = {
  children: React.ReactNode;
  propertyType: "保有中" | "検討中";
  propertyHolder: PropertyHolder;
  monthlyBalanceSheet: MonthlyBalanceSheet;
  dropDownMenuItems: DropDownMenuItem[];
  onBack?: () => void;
  onSave?: () => void;
};

export const ManagementDetailTemplate: React.FC<Props> = (props) => {
  const { children, propertyType, propertyHolder, monthlyBalanceSheet, dropDownMenuItems } = props;
  const { isMobile } = useDevice();
  const classes = useStyles(props);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onBack = () => {
    history.push("/management");
  };
  const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const onClickDocument = () => {
    history.push("/property_document_list");
  };

  const formattedTotalPriceOfProperty = Math.round(
    propertyHolder.property.total_price_of_property / 10000
  ).toLocaleString();
  const formattedMonthlyFee = Math.round(
    getLatestMonthlyFeeFromProperty(propertyHolder.lease_contracts)
  ).toLocaleString();

  // 入居中かどうか
  const isCurrentInUse = isPropertyCurrentInUse(propertyHolder.lease_contracts);

  return (
    <MainTemplate>
      <Box className={classes.background}>
        <div className={classes.media}>
          <Box className={classes.iconContainer}>
            <IconButton
              className={classes.iconButton}
              classes={{ root: classes.iconRoot, label: classes.labelMenuIcon }}
              onClick={onBack}
            >
              <ArrowBackIcon fontSize="large" />
            </IconButton>
            <IconButton
              className={classes.menuButton}
              classes={{ root: classes.iconRoot, label: classes.labelMenuIcon }}
              onClick={handleSettingsClick}
            >
              <MoreVertIcon fontSize="large" />
            </IconButton>
          </Box>
          <DropDownMenu anchorEl={anchorEl} onClose={handleSettingsClose} itemOptions={dropDownMenuItems} />
        </div>
        <Box display="flex" justifyContent="center">
          <Box className={classes.propertyInfo}>
            <Box display="flex" alignItems="center" mb={1}>
              <Box mr={2}>
                {/* 保有ステータス */}
                {propertyType === "保有中" && <CustomChip label="保有中" outlined />}
                {propertyType === "検討中" && <CustomChip label="検討中" outlined color="default" />}
              </Box>
              <Box display="flex" alignItems="flex-end">
                <OccupancyStatus label={isCurrentInUse ? "入居中" : "空室中"} isActive={isCurrentInUse} />
              </Box>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box>
                <Box mb={1}>
                  <Typography variant="h2" color="textPrimary">
                    {propertyHolder.property.name}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography color="textSecondary">{getPropertyAddressString(propertyHolder)}</Typography>
                </Box>
              </Box>

              <IconButton
                aria-label="document"
                className={classes.icon}
                style={{
                  width: "50px",
                  height: "50px",
                  padding: "8px",
                  backgroundColor: "#00277F",
                  borderRadius: "8px",
                }}
                onClick={onClickDocument}
              >
                <label
                  htmlFor="document"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <DescriptionIcon
                    style={{
                      color: "#fff",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                  <span style={{ marginTop: "4px", fontSize: "10px", color: "#fff" }}>書類</span>
                </label>
              </IconButton>
            </Box>
            <Box className={classes.propertyInfoDetail}>
              <Box className={classes.propertyInfoDetailText}>
                <Box mr={2}>
                  <Typography variant="caption" color="textSecondary">
                    購入価格
                  </Typography>
                  <Typography variant="h4" color="textPrimary">
                    {formattedTotalPriceOfProperty}万円
                  </Typography>
                </Box>
                <Box mr={2}>
                  <Typography variant="caption" color="textSecondary">
                    賃料（管理費込）
                  </Typography>
                  <Typography variant="h4" color="textPrimary">
                    {formattedMonthlyFee}円
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption" color="textSecondary">
                    表面利回り / 実質利回り
                  </Typography>
                  <Typography variant="h4" color="textPrimary">
                    {getYieldRate(
                      monthlyBalanceSheet.result.rent_income + monthlyBalanceSheet.result.management_fee_income,
                      propertyHolder.property.total_price_of_property
                    )}
                    % / {getActualYieldRateByCashflowResult(propertyHolder, monthlyBalanceSheet.result)}%
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* タブ以降は子コンポーネント */}
        {children}

        {/* PCのみフッター表示 */}
        {isMobile ? <NavigationBar path={NavigationPath.PROPERTY_MANAGEMENT} /> : <Footer />}
      </Box>
    </MainTemplate>
  );
};
