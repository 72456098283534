import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import dayjs from "dayjs";
import * as React from "react";
import { useRecoilValue } from "recoil";

import { UserUpdate, UserUpdateWayToProposeEnum, UserWayToProposeEnum } from "../../api/generated";
import { PropallyApiFactory } from "../../module/custom_api_factory";
import { Card } from "../../scripts/components/card";
import { UserState } from "../../view_models/atoms";

export const DeleteAccount: React.FC = () => {
  const user = useRecoilValue(UserState);

  const patchUser = async () => {
    const fp = PropallyApiFactory();

    const sendValues: UserUpdate = {
      ...user,
      withdrawal_intended_at: dayjs().format(),
      way_to_propose: user.way_to_propose as unknown as UserUpdateWayToProposeEnum,
    };

    try {
      await fp.v1UserPatch(sendValues, { withCredentials: true });
    } catch (e) {}
  };

  const handleClick = () => {
    patchUser();
    window.open("https://form.run/@propally-1619689827", "_blank", "noopener,noreferrer");
  };

  return (
    <Card>
      <Box textAlign="center" my={7}>
        <Typography variant="h5" component="div">
          <Box mb={3} fontWeight="bold">
            退会手続き
          </Box>
        </Typography>
        <Box mb={3}>
          <Typography variant="body2" component="div">
            退会に関しては以下の退会申請フォームより申請ください。
            <br />
            担当者が申請内容を確認し、アカウントの削除後、その旨をメールにて通知致します。
            <br />
            <Typography component="span" variant="body2" color="secondary">
              退会手続きをされますと登録データは全て削除されます
            </Typography>
            ので、ご注意ください。
          </Typography>
        </Box>
        <Box justifyContent="center" display="flex">
          <Button type="submit" variant="contained" color="primary" size="medium" onClick={handleClick}>
            退会申請フォームへ
          </Button>
        </Box>
      </Box>
    </Card>
  );
};
